export default [
  {
    path: "/domain/list",
    name: "list-domain-page",
    component: () => import("@/views/domains/List.vue"),
    meta: {
      resource: 'all',
      action: 'manage',
      pageTitle: "List Domain",
      breadcrumb: [
        {
          text: "Domain"
        },
        {
          text: "List",
          active: true
        }
      ]
    }
  }
];
