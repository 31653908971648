import Vue from 'vue'
import Toast from 'vue-toastification'

// Toast Notification Component Styles
import '@core/scss/vue/libs/toastification.scss'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

/**
 * NOTE: If you are using other transition them make sure to import it in `src/@core/scss/vue/libs/notification.scss` from it's source
 */
Vue.use(Toast, {
    hideProgressBar: false,
    closeOnClick: false,
    closeButton: false,
    icon: false,
    timeout: 3000,
    transition: 'Vue-Toastification__fade',
})

Vue.prototype.$success = (message) => Vue.prototype.$toast({
    component: ToastificationContent,
    props: {
        title: 'Success',
        icon: 'CheckIcon',
        text: message,
        variant: 'success',
    },
})

Vue.prototype.$error = (message) => Vue.prototype.$toast({
    component: ToastificationContent,
    props: {
        title: 'Error',
        icon: 'XCircleIcon',
        text: message,
        variant: 'danger',
    },
})

Vue.prototype.$warning = (message) => Vue.prototype.$toast({
    component: ToastificationContent,
    props: {
        title: 'Warning',
        icon: 'XCircleIcon',
        text: message,
        variant: 'warning',
    },
})

Vue.prototype.$notification = (message) => Vue.prototype.$toast({
    component: ToastificationContent,
    props: {
        title: 'Notification',
        icon: 'BellIcon',
        text: message,
        variant: 'primary',
    },
})
