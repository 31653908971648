import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: process.env.VUE_APP_API_URL,
    // timeout: 1000,
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: `Bearer ${localStorage.getItem(process.env.VUE_APP_CLIENT_TOKEN)}`,
    },
})
// InvalidAuthenticationToken
Vue.prototype.$http = axiosIns

export default axiosIns
