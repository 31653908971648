export default [
    {
        path: '/account/list-user',
        name: 'list-user-page',
        component: () => import('@/views/users/List.vue'),
        meta: {
            pageTitle: 'List User',
            breadcrumb: [
                {
                    text: 'Account',
                },
                {
                    text: 'List User',
                    active: true,
                },
            ],
            resource: 'all',
            action: 'update',
        },
    },
    {
        path: '/account/list-admin',
        name: 'list-admin-page',
        component: () => import('@/views/admins/List.vue'),
        meta: {
            pageTitle: 'List Admin',
            breadcrumb: [
                {
                    text: 'Account',
                },
                {
                    text: 'List Admin',
                    active: true,
                },
            ],
            resource: 'all',
            action: 'manage',
        },
    },
    {
        path: '/account/list-log',
        name: 'list-log-page',
        component: () => import('@/views/log/List.vue'),
        meta: {
            pageTitle: 'List Log',
            breadcrumb: [
                {
                    text: 'Account',
                },
                {
                    text: 'List Log',
                    active: true,
                },
            ],
            resource: 'all',
            action: 'update',
        },
    },
    // {
    //     path: '/account/profile',
    //     name: 'profile-page',
    //     component: () => import('@/views/accounts/profile/Profile.vue'),
    //     meta: {
    //         pageTitle: 'Profile',
    //         breadcrumb: [
    //             {
    //                 text: 'Account',
    //             },
    //             {
    //                 text: 'Profile',
    //                 active: true,
    //             },
    //         ],
    //     },
    // },
]
