export default [
    {
        path: '/group/list',
        name: 'list-group-page',
        component: () => import('@/views/groups/List.vue'),
        meta: {
            resource: 'all',
            action: 'update',
            pageTitle: 'List Group',
            breadcrumb: [
                {
                    text: 'Group',
                },
                {
                    text: 'List',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/group/list/members',
        name: 'list-member-group-page',
        component: () => import('@/views/groups/ListMember.vue'),
        meta: {
            resource: 'all',
            action: 'update',
            pageTitle: 'List Member Group',
            breadcrumb: [
                {
                    text: 'Group',
                },
                {
                    text: 'List Member',
                    active: true,
                },
            ],
        },
    },
]
