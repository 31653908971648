import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

// import * as moment from 'moment'
import i18n from '@/libs/i18n'
import * as _ from 'lodash'
import CKEditor from 'ckeditor4-vue'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/filters'
import Swal from "sweetalert2";

const moment = require('moment')
// import '@/libs/vue-select'
// import '@/libs/tour'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(CKEditor)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.prototype.$swal_show = (timeout = 3000) => {
    Swal.fire({
        title: 'Waiting...',
        width: '50%',
        imageUrl: require('@/assets/images/logo/logo.svg'),
        background: `rgba(255, 255, 255, 1)`,
        color: '#2bb898',
        imageWidth: '50%',
        imageHeight: 100,
        backdrop: `rgba(0, 0, 0, 0.7)`,
        allowOutsideClick: false,
        allowEscapeKey: false,
        timer: timeout,
        didOpen(popup) {
            Swal.showLoading()
        },
        // timerProgressBar: true,
    })
}
Vue.prototype.$swal_hide = () => {
    return new Promise((resolve, reject) => {
        Swal.hideLoading()
        Swal.close()
    })
}

// eslint-disable-next-line import/no-extraneous-dependencies
window.moment = moment
window._ = _

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app')
