import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import {
    BButton, BCol, BDropdown, BDropdownItem, BForm, BFormCheckbox, BModal, BRow, BImg, BCardText
} from "bootstrap-vue";
import { quillEditor } from 'vue-quill-editor'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('BRow', BRow)
Vue.component('BCol', BCol)
Vue.component('BButton', BButton)
Vue.component('BModal', BModal)
Vue.component('BForm', BForm)
Vue.component('BFormCheckbox', BFormCheckbox)
Vue.component('BDropdownItem', BDropdownItem)
Vue.component('BDropdown', BDropdown)
Vue.component('BImg', BImg)
Vue.component('QuillEditor', quillEditor)
Vue.component('BCardText', BCardText)
