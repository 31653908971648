import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'

const { jwt } = useJwt(axios, {
    loginEndpoint: '/auth/login',
    registerEndpoint: '/auth/register',
    refreshEndpoint: '/auth/refresh-token',
    logoutEndpoint: '/auth/logout',
    tokenType: 'Bearer',
    storageTokenKeyName: 'accessToken',
    storageRefreshTokenKeyName: 'refreshToken',
})
export default jwt
